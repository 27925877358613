<template>
  <div class="content">
    <heads></heads>
    <div style="padding: 30px 120px;">
      <div class="nav-flex">
        <!-- <div>资产账户</div> -->
        <div class="nav-active">{{ $t('assets.jl') }}</div>
      </div>
      <div class="main">
        <div class="main-head">
          <div class="quotation-box">
            <div class="quotation-box-head">
              <div style="display: flex;align-items: center;">
                <div class="quotation-box-nav" :class="[activeIndex==1?'quotation-active':'']" @click="btn(1)">
                  <div class="quotation-box-tit">{{ $t('assets.czjl') }}</div>
                </div>
                <div class="quotation-box-nav" :class="[activeIndex==2?'quotation-active':'']" @click="btn(2)">
                  <div class="quotation-box-tit">{{ $t('assets.txjl') }}</div>
                </div>
              </div>
            </div>
            <div v-if="activeIndex == 1">
              <div class="quotation-list">
                <div class="quotation-list-tit">
                  <div style="text-align: left;">{{ $t('assets.bz') }}</div>
                  <div>{{ $t('assets.sj') }}</div>
                  <div>{{ $t('assets.czse') }}</div>
                  <div style="text-align: right;width:30%;">{{ $t('assets.dz') }}</div>
                  <div style="text-align: right;width:20%;">{{ $t('assets.zt') }}</div>
                </div>
              </div>
              <div class="quotation-list" v-for="(item,index) in getData" :key="index">
                <div class="quotation-list-tit">
                  <div style="text-align: left;">{{ item.coin_name }}</div>
                  <div>{{ item.created_date }}</div>
                  <div style="text-align: center;">{{ (item.money * 1).toFixed(6) }}</div>
                  <div class="hash-box" style="text-align: right;width: 30%;">{{ item.from_a }}</div>
                  <div style="text-align: right;width: 20%;">{{ $t('assets.ywc') }}</div>
                </div>
              </div>
            </div>
            <div v-if="activeIndex == 2">
              <div class="quotation-list">
                <div class="quotation-list-tit quotation-list-tit3">
                  <div style="text-align: left;width: 6%;">{{ $t('assets.bz') }}</div>
                  <div>{{ $t('assets.sj') }}</div>
                  <div style="text-align: right;">{{ $t('assets.tbsl') }}</div>
                  <div style="text-align: right;width:28%;">{{ $t('assets.tbdz') }}</div>
                  <div style="text-align: center;">{{ $t('assets.sxf') }}</div>
                  <div style="text-align: center;">{{ $t('assets.jsse') }}</div>
                  <div style="text-align: right;width: 8%;">{{ $t('assets.zt') }}</div>
                </div>
              </div>
              <div class="quotation-list" v-for="(item,index) in getData" :key="index">
                <div class="quotation-list-tit quotation-list-tit3">
                  <div style="text-align: left;width: 6%;">{{item.coin_name}}</div>
                  <div>{{item.created_at}}</div>
                  <div style="text-align: right;">{{(item.money*1).toFixed(6)}}</div>
                  <div class="hash-box" style="text-align: right;width:28%;">{{item.address}}</div>
                  <div style="text-align: center;">{{(item.service_money*1).toFixed(6)}}</div>
                  <div style="text-align: center;">{{(item.withdraw_money*1).toFixed(6)}}</div>
                  <div style="text-align: right;width: 8%;">{{item.status_str}}</div>
                </div>
              </div>
            </div>
            <pagination @current-change="handleCurrentChange"  :total="total"></pagination>
            <no-data v-if="getData.length<=0"></no-data>

          </div>
        </div>
      </div>
    </div>
    <!-- <footerb></footerb> -->
  </div>
</template>

<script>
import heads from '../../components/header.vue'
import footerb from '../../components/footer.vue'
import NoData from "@/components/noData/noData";
import {getWithdrawList} from "@/api/withdraw";
import {getChargeList} from "@/api/money";
import Pagination from "@/components/pagination/pagination";

export default {
  data() {
    return {
      activeIndex: 1,
      page: 1,
      total: null,
      getData: [],
      isAjax: false

    }
  },
  components: {
    Pagination,
    NoData,
    footerb,
    heads
  },
  mounted() {
    this.startList()
  },
  methods: {
    btn(id) {
      this.activeIndex = id
      this.startList()
    },
    startList() {
      this.page = 1
      this.total = null
      this.getData = []
      this.isAjax = false
      this.getList()
    },
    handleCurrentChange(val) {
      console.log('val:' + val)
      this.page = val
      this.getList()
    },
    getList() {
      if (this.total !== null && this.total <= this.getData.length) {
        return false
      }
      console.log('------------')
      if (this.isAjax) {
        return false
      }
      this.isAjax = true
      if (this.activeIndex === 1) {
        this.getChargeList()
      } else {
        this.getWithdrawList()
      }
    },
    getWithdrawList() {
      getWithdrawList({'page': this.page}).then((res) => {
        if (res.code === 1) {
          this.total = res.data.total
          this.getData = res.data.items
        }
        this.isAjax = false
      })
    },
    getChargeList() {
      console.log('this.page :' + this.page)
      getChargeList({'page': this.page}).then((res) => {
        if (res.code === 1) {
          this.total = res.data.total
          this.getData = res.data.items
        }
        this.isAjax = false
      })
    }

  }
};

</script>
<style scoped lang="scss">


html {
  background: #171e28;
}

.hash-box{
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;

}

.main {
  width: 100%;
  height: auto;
  background: #1F282F;
  padding: 30px;
  box-sizing: border-box;
}

.main-head {
  width: 100%;
  height: auto;
  background: #171E28;
  margin-bottom: 20px;
  color: #FFFFFF;
}

.nav-flex {
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(255, 255, 255, 0.8);
  font-size: 16px;
  margin-bottom: 30px;
  cursor: pointer;


}

.nav-flex div {
  margin-right: 40px;
  padding-bottom: 6px;
}

.nav-active {
  border-bottom: 1px solid #FFC300;
  color: #FFC300;
}

.quotation-box {
  width: 100%;
  height: auto;
  /* background: #29343D; */
  padding: 28px 0 16px 0;
  margin-bottom: 30px;
}

.quotation-box-img {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.quotation-box-tit {
  /* margin-bottom: 16px; */
  /* color: #FFFFFF; */
}

.quotation-box-nav {
  display: flex;
  align-items: center;
  padding-bottom: 16px;
  color: rgba(255, 255, 255, 0.6);
  margin-right: 32px;
}

.quotation-active {
  border-bottom: 1px solid #FFC300;
  color: #FFC300 !important;
}

.quotation-box-head {
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #1F282F;
  margin-bottom: 10px;
  cursor: pointer;
  padding: 0 60px;
  box-sizing: border-box;
}

.quotation-list {
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.6);
  border-bottom: 1px solid #1F282F;
  padding-bottom: 10px;
  margin-bottom: 16px;
}

.quotation-list-tit {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 60px;
  text-align: center;
}

.quotation-list-tit div {
  width: 25%;
  font-size: 12px;
}

.quotation-list-tit3 div {
  width: 14%;
  font-size: 12px;
}


</style>
